import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const Testimonials: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  
  const testimonials = [
    {
      quote: "La consultoría estratégica proporcionada transformó completamente nuestra visión digital. Los resultados superaron nuestras expectativas.",
      role: "Director de Tecnología",
      industry: "Sector Financiero"
    },
    {
      quote: "La metodología implementada nos permitió acelerar nuestra transformación digital manteniendo la calidad y eficiencia.",
      role: "CEO",
      industry: "Manufactura Industrial"
    },
    {
      quote: "El enfoque estratégico y la experiencia en múltiples industrias fueron clave para el éxito de nuestro proyecto.",
      role: "Gerente de Innovación",
      industry: "Retail"
    },
    {
      quote: "La implementación de soluciones digitales mejoró significativamente la experiencia de nuestros pacientes.",
      role: "Director Médico",
      industry: "Sector Salud"
    },
    {
      quote: "La optimización de nuestra cadena de suministro ha sido transformadora para nuestro negocio.",
      role: "Director de Operaciones",
      industry: "Logística"
    },
    {
      quote: "La plataforma educativa desarrollada ha revolucionado nuestra forma de enseñar.",
      role: "Rector",
      industry: "Educación Superior"
    }
  ]

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % testimonials.length)
    }, 5000)
    return () => clearInterval(timer)
  }, [testimonials.length])

  const visibleTestimonials = [
    testimonials[currentIndex],
    testimonials[(currentIndex + 1) % testimonials.length],
    testimonials[(currentIndex + 2) % testimonials.length]
  ]

  return (
    <section id="testimonios" className="py-32">
      <div className="container mx-auto px-4 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">Testimonios</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            La satisfacción de nuestros clientes es nuestro mejor aval
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <AnimatePresence mode='wait'>
            {visibleTestimonials.map((testimonial, index) => (
              <motion.div
                key={`${currentIndex}-${index}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <TestimonialCard {...testimonial} />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        {/* Indicadores */}
        <div className="flex justify-center mt-8 space-x-4">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              aria-label={`Ver testimonio ${index + 1} de ${testimonials.length}`}
              aria-current={index === currentIndex ? "true" : "false"}
              className={`w-12 h-12 p-4 flex items-center justify-center rounded-full transition-all duration-300 hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-neon-green focus:ring-offset-2 focus:ring-offset-black`}
            >
              <span className={`w-3 h-3 rounded-full transition-all duration-300 ${
                index === currentIndex ? 'bg-neon-green w-6' : 'bg-white/20'
              }`} />
            </button>
          ))}
        </div>

        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.3 }}
          className="mt-16 text-center"
        >
          <div className="p-8 border border-white/10 rounded-xl bg-black/20">
            <h3 className="text-2xl font-bold text-white mb-4">
              ¿Listo para transformar tu empresa?
            </h3>
            <p className="text-gray-400 mb-8 max-w-2xl mx-auto">
              Únete a las empresas que ya han potenciado su crecimiento con nuestra consultoría estratégica
            </p>
            <a 
              href="#contacto"
              className="inline-flex items-center gap-2 px-8 py-4 bg-neon-green text-black font-bold rounded hover:bg-neon-green/90 transition-colors"
            >
              Agenda una Consulta Gratuita
              <span className="text-xl">→</span>
            </a>
          </div>
        </motion.div>
      </div>
    </section>
  )
}

interface TestimonialCardProps {
  quote: string
  role: string
  industry: string
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ quote, role, industry }) => (
  <div className="p-8 border border-white/10 rounded-xl bg-black/20 hover:border-neon-green/30 transition-all duration-300 h-full">
    <div className="mb-6">
      <div className="text-neon-green text-6xl opacity-20">"</div>
    </div>
    <blockquote className="text-gray-400 mb-6">
      {quote}
    </blockquote>
    <div className="border-t border-white/10 pt-4">
      <p className="text-white font-semibold">{role}</p>
      <p className="text-sm text-neon-green">{industry}</p>
    </div>
  </div>
)

export default Testimonials 