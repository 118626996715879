import React from 'react'
import { motion } from 'framer-motion'
import { BiChevronRight } from 'react-icons/bi'

const Cases: React.FC = () => {
  const cases = [
    {
      industry: "Fintech",
      title: "Transformación Digital en Empresa Financiera",
      metrics: [
        "Reducción del 40% en tiempo de procesamiento",
        "Mejora del 60% en satisfacción del cliente",
        "Implementación exitosa de sistema cloud-native",
        "ROI positivo en 8 meses"
      ]
    },
    {
      industry: "Manufactura",
      title: "Optimización de Procesos Industriales",
      metrics: [
        "Automatización del 70% de procesos manuales",
        "Reducción del 30% en costos operativos",
        "Implementación de IoT en planta productiva",
        "Mejora del 45% en eficiencia operativa"
      ]
    },
    {
      industry: "Retail",
      title: "Plataforma E-commerce Omnicanal",
      metrics: [
        "Incremento del 150% en ventas online",
        "Integración exitosa de canales físicos y digitales",
        "Reducción del 35% en costos de inventario",
        "Mejora del 80% en experiencia de usuario"
      ]
    },
    {
      industry: "Salud",
      title: "Digitalización de Servicios Médicos",
      metrics: [
        "Implementación de telemedicina",
        "Reducción del 50% en tiempos de espera",
        "Integración de expedientes electrónicos",
        "Mejora del 70% en seguimiento a pacientes"
      ]
    },
    {
      industry: "Logística",
      title: "Optimización de Cadena de Suministro",
      metrics: [
        "Trazabilidad en tiempo real",
        "Reducción del 40% en costos logísticos",
        "Automatización de rutas de entrega",
        "Mejora del 55% en tiempos de entrega"
      ]
    },
    {
      industry: "Educación",
      title: "Plataforma de Aprendizaje Digital",
      metrics: [
        "Implementación de LMS personalizado",
        "Incremento del 200% en alcance estudiantil",
        "Integración de herramientas colaborativas",
        "90% de adopción por usuarios"
      ]
    }
  ]

  return (
    <section id="casos" className="py-32 bg-black/50">
      <div className="container mx-auto px-4 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">Casos de Éxito</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Resultados tangibles y medibles en proyectos de transformación
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cases.map((caseStudy, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <CaseCard {...caseStudy} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}

interface CaseCardProps {
  industry: string
  title: string
  metrics: string[]
}

const CaseCard: React.FC<CaseCardProps> = ({ industry, title, metrics }) => (
  <div className="p-8 border border-white/10 rounded-xl bg-black/20 hover:border-neon-green/30 transition-all duration-300 h-full">
    <span className="text-sm text-neon-green mb-2 block">{industry}</span>
    <h3 className="text-xl font-bold text-white mb-6">{title}</h3>
    <ul className="space-y-4">
      {metrics.map((metric, index) => (
        <li key={index} className="flex items-start gap-2 text-gray-400">
          <BiChevronRight className="text-neon-green mt-1 flex-shrink-0" />
          <span>{metric}</span>
        </li>
      ))}
    </ul>
  </div>
)

export default Cases 