import React from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { motion } from 'framer-motion'

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="relative bg-black/80 border-t border-white/10">
      {/* Efecto de resplandor */}
      <div className="absolute inset-0 bg-gradient-to-t from-neon-green/5 to-transparent pointer-events-none" />
      
      <div className="relative container mx-auto px-4 lg:px-8 py-16">
        {/* Grid principal */}
        <div className="grid md:grid-cols-3 gap-12 lg:gap-16">
          {/* Columna 1: Logo y Descripción */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <h3 className="text-3xl font-bold text-white tracking-tight">
              <span className="text-neon-green">Reyner</span> González<span className="text-neon-green">.</span>
            </h3>
            <p className="text-gray-400 leading-relaxed">
              Transformando empresas a través de soluciones tecnológicas innovadoras y consultoría estratégica de alto impacto.
            </p>
          </motion.div>

          {/* Columna 2: Enlaces Rápidos */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
          >
            <h4 className="text-white font-semibold mb-6 text-lg">Enlaces Rápidos</h4>
            <ul className="space-y-4">
              {[
                { href: '#servicios', label: 'Servicios' },
                { href: '#industrias', label: 'Industrias' },
                { href: '#metodologia', label: 'Metodología' },
                { href: '#marcos', label: 'Marcos' },
                { href: '#casos', label: 'Casos de Éxito' }
              ].map((link) => (
                <li key={link.href}>
                  <a 
                    href={link.href} 
                    className="text-gray-400 hover:text-neon-green transition-colors duration-300 flex items-center group"
                  >
                    <span className="w-1.5 h-1.5 rounded-full bg-neon-green/50 mr-2 group-hover:scale-125 transition-transform duration-300"></span>
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>

          {/* Columna 3: Contacto */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            <h4 className="text-white font-semibold mb-6 text-lg">Contacto</h4>
            <div className="space-y-6">
              <motion.div 
                className="p-6 rounded-xl border border-white/10 bg-white/5 backdrop-blur-sm hover:border-neon-green/30 transition-colors duration-300"
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="flex items-center space-x-3 text-gray-300 mb-3">
                  <FaEnvelope size={20} className="text-neon-green" />
                  <a 
                    href="mailto:info@reynergonzalez.com" 
                    className="hover:text-neon-green transition-colors duration-300"
                  >
                    info@reynergonzalez.com
                  </a>
                </div>
                <p className="text-sm text-gray-500 leading-relaxed">
                  Respetamos tu privacidad y protegemos tus datos personales de acuerdo con las normativas vigentes.
                </p>
              </motion.div>
            </div>
          </motion.div>
        </div>

        {/* Copyright */}
        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.3 }}
          className="mt-16 pt-8 border-t border-white/10 text-center"
        >
          <p className="text-gray-400">
            © {currentYear} <span className="text-neon-green font-medium">Reyner González</span>. 
            Todos los derechos reservados.
          </p>
          <p className="text-gray-500/60 text-xs mt-2 font-light">
            Última actualización: 23 de Marzo de 2025
          </p>
        </motion.div>
      </div>
    </footer>
  )
}

export default Footer 